import React from "react"
import { Link } from "gatsby"
import Banner from "../components/Banner"
import Layout from "../components/Layout"

const NotFoundPage = () => (
  <Layout>
    <div className="main">
      <Banner title="404" tagline="Not Found" />
      <div className="grid-container">
        <div className="grid-x grid-padding-x">
          <div className="cell large-9">
            <h1>
              Sorry, but the page you are looking for has not been found.{" "}
            </h1>
            <p className="lead">
              Try checking the URL for errors or return to the{" "}
              <Link to="/">homepage</Link>.
            </p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
