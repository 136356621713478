import React from "react"

class Banner extends React.Component {
  render() {
    const { title, tagline } = this.props
    return (
      <div className="ft-banner pt-6">
        <div className="grid-container">
          <div className="cell px-5 text-center">
            <h1 className="mb-5">{tagline} </h1>
            <h3 className="mb-5">{title}</h3>
          </div>
        </div>
      </div>
    )
  }
}
export default Banner
